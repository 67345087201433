import { render, staticRenderFns } from "./DialogBase.vue?vue&type=template&id=4a4caf45&scoped=true"
import script from "./DialogBase.vue?vue&type=script&lang=js"
export * from "./DialogBase.vue?vue&type=script&lang=js"
import style0 from "./DialogBase.vue?vue&type=style&index=0&id=4a4caf45&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a4caf45",
  null
  
)

export default component.exports