import { render, staticRenderFns } from "./VideoPlayerController.vue?vue&type=template&id=cb970244&scoped=true"
import script from "./VideoPlayerController.vue?vue&type=script&lang=js"
export * from "./VideoPlayerController.vue?vue&type=script&lang=js"
import style0 from "./VideoPlayerController.vue?vue&type=style&index=0&id=cb970244&prod&scoped=true&lang=css"
import style1 from "./video_player.css?vue&type=style&index=1&id=cb970244&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb970244",
  null
  
)

export default component.exports